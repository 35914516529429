import React from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import useAPIVPN from '@/hooks/api/useAPIVPN';
import { APIErrorClass } from '@/shared/api_errors';
import AlertBox from '@/slices/AlertBox';
import FormGroup from '@/slices/FormGroup';
import { EthernetPort, LoaderCircle } from 'lucide-react';
import { useEffect } from 'react';
import Input from '@/components/Input';
import { Control, Controller, UseFormGetValues } from 'react-hook-form';
import { NASInputForm } from '../NASByIdPage';

interface WithVPNProps {
	nasId: string;
	control: Control<NASInputForm, unknown>;
	getValues: UseFormGetValues<NASInputForm>;
	onNASChanged: () => void;
}

export default function WithVPN({ nasId, control, getValues, onNASChanged }: WithVPNProps) {
	const {
		vpnParameters,
		getVPNParameters,
		removeFromVPN,
		testVPNConnection,
		vpnTest,
		vpnLoading,
		error,
		clearError,
		clearVPNTest,
	} = useAPIVPN();

	useEffect(() => {
		if (vpnParameters) return;
		getVPNParameters();
	}, [vpnParameters]);

	async function handleTestConnection() {
		const result = await testVPNConnection(nasId);
		if (!result) return;
		onNASChanged();
	}

	async function handleRemoveVPN() {
		const result = await removeFromVPN(nasId);
		if (!result) return;
		onNASChanged();
	}

	return (
		<FormGroup
			title="VPN"
			grow
			action={
				<div className="flex flex-col items-end">
					<span className="flex gap-2 items-center text-nowrap text-sm">
						VPN is set up
						<Icon icon={EthernetPort} className="text-success" />
					</span>
				</div>
			}
		>
			{error && (
				<AlertBox
					type={error.error_type() === APIErrorClass.InternalError ? 'danger' : 'warning'}
					title={
						error.error_type() === APIErrorClass.InternalError
							? 'Ops! Something went wrong.'
							: 'Oh, no! Validation errors found.'
					}
					message={
						error.error_type() === APIErrorClass.InternalError
							? 'An internal problem occurred and it was not possible to process your request.'
							: [error.error, ...(error.message ? error.message : [])].join(', ')
					}
					onOk={clearError}
				/>
			)}
			{vpnTest?.error && (
				<AlertBox
					type="warning"
					title="VPN Connection Test Failed"
					message={vpnTest.error}
					onOk={clearVPNTest}
				/>
			)}
			{vpnTest?.success && (
				<AlertBox
					type="success"
					title="VPN Connection Test Success"
					message={vpnTest.data || 'Test completed!'}
					onOk={clearVPNTest}
				/>
			)}
			<div className={`flex flex-col gap-4 relative ${!vpnParameters ? 'opacity-25 animate-pulse' : ''}`}>
				<div className="flex gap-2">
					<div className="w-1/2">
						<Input value={getValues('vpn_ip') || ''} label="VPN IP" disabled />
					</div>
					<div className="w-1/2">
						<Controller
							control={control}
							name="vpn_api_port"
							render={({ field }) => (
								<Input {...field} value={field.value || ''} label="VPN API port" placeholder="8728" />
							)}
						/>
					</div>
				</div>
				<div className="flex gap-2">
					<Button
						text="Test Connection"
						variant="success"
						icon={vpnLoading ? LoaderCircle : undefined}
						iconSpin={vpnLoading}
						disabled={vpnLoading}
						onClick={handleTestConnection}
					/>
					<Button
						text="Remove VPN configuration"
						variant="danger"
						style="outline"
						onClick={handleRemoveVPN}
						icon={vpnLoading ? LoaderCircle : undefined}
						iconSpin={vpnLoading}
						disabled={vpnLoading}
					/>
				</div>
			</div>
		</FormGroup>
	);
}
