import React from 'react';
import CRUDFormPage from './CRUDFormPageLegacy';
import { CRUDFormGroupType } from '../components/CRUDFormGroupLegacy';

const groups: Array<CRUDFormGroupType> = [
	{
		key: 'basic-data',
		title: 'Basic Data',
		rows: [
			{
				key: 'row-1',
				fields: [
					{ property: 'name', label: 'Name', highlight: true, proportion: 'w-3/6' },
					{ property: 'document_number', label: 'ID Number', proportion: 'w-2/6' },
					{ property: 'document_type', label: 'Doc Type', proportion: 'w-1/6' },
				],
			},
			{
				key: 'row-2',
				fields: [{ property: 'address', label: 'Address', proportion: 'w-full' }],
			},
		],
	},
];

function ClientByIdPage() {
	return (
		<CRUDFormPage
			title="Clients"
			groups={groups}
			endpoint="/client"
			backwardUrl="/customers"
			createUrl="/customers/new"
		/>
	);
}

export default ClientByIdPage;
