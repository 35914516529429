import React from 'react';
import CRUDFormFieldLegacy, { CRUDFormFieldTypeLegacy } from './CRUDFormFieldLegacy';
import FormGroup from '../slices/FormGroup';
import CRUDFormSelectLegacy, { CRUDFormSelectTypeLegacy } from './CRUDFormSelectLegacy';
import { LucideIcon } from 'lucide-react';

export interface CRUDFormGroupType {
	key: string;
	title?: string;
	rows: Array<CRUDFormGroupRowLegacy>;
	action?: {
		icon: LucideIcon;
		action: () => void;
	};
}

export interface CRUDFormGroupRowLegacy {
	key: string;
	fields: Array<CRUDFormFieldTypeLegacy | CRUDFormSelectTypeLegacy>;
}

export interface CRUDFormGroupProps {
	group: CRUDFormGroupType;
	form: never;
	setForm: (form: object) => void;
}

function CRUDFormGroupLegacy({ group, form, setForm }: CRUDFormGroupProps) {
	return (
		<FormGroup key={group.key} title={group.title} action={group.action}>
			{group.rows.map((row) => (
				<div key={row.key} className="mb-4 flex gap-2">
					{row.fields.map((field) =>
						(field as CRUDFormSelectTypeLegacy).options !== undefined ? (
							<CRUDFormSelectLegacy
								key={field.property}
								field={field as CRUDFormSelectTypeLegacy}
								form={form}
								setForm={setForm}
							/>
						) : (
							<CRUDFormFieldLegacy key={field.property} field={field} form={form} setForm={setForm} />
						),
					)}
				</div>
			))}
		</FormGroup>
	);
}

export default CRUDFormGroupLegacy;
