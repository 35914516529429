import React from 'react';

import { DataTableField } from '../../components/DataTable';
import { Filter } from '../../slices/Filters';

import PaginatedPageLegacy from '../PaginatedPageLegacy';
import { Plan } from '../../shared/models/service/Plan';
import { toLocaleNumberString } from '../../shared/utils';

const fields: Array<DataTableField<Plan>> = [
	{ title: 'Name', property: 'name' },
	{ title: 'Description', property: 'description' },
	{ title: 'Recurrency', property: 'recurrency_interval' },
	{
		title: 'Services',
		property: '',
		extractor: (row: Plan) => row.service_plan_type.map((o) => o.service_type.name).join(' \\ '),
	},
	{
		title: 'Price',
		property: 'price',
		align: 'right',
		extractor: (row: Plan) => (row.price ? `${row.currency.symbol} ${toLocaleNumberString(row.price / 100)}` : ''),
	},
];
const endpoint = '/service/plan';
const formUrl = '/service/plans/new';

export default function PlanPage() {
	const filters: Array<Filter> = [
		{
			id: 'name',
			label: 'Name:',
			operator: {
				field: 'name',
				op: 'contains',
			},
		},
		{
			id: 'description',
			label: 'Description:',
			operator: {
				field: 'description',
				op: 'contains',
			},
		},
	];

	return (
		<PaginatedPageLegacy
			title="Plans"
			endpoint={endpoint}
			initialSortField="description"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
