import { CRUDFormGroupType } from '../../components/CRUDFormGroupLegacy';
import { Subscription } from '../../shared/models/Subscription';
import { CRUDFormFieldAutoCompleteLegacy } from '../../components/CRUDFormFieldLegacy';
import { Plan } from '../../shared/models/service/Plan';
import { Client } from '../../shared/models/Client';
import { NAS, NASPool } from '../../shared/models/infra/NAS';

export function generateCreateGroups(
	form: Subscription,
	setForm: (form: Subscription) => void,
	pools?: Array<NASPool>,
) {
	const groups: Array<CRUDFormGroupType> = [
		{
			key: 'finance-data',
			title: 'Finance',
			rows: [
				{
					key: 'row-1',
					fields: [
						{
							property: 'service_plan_id',
							label: 'Plan',
							proportion: 'w-1/2',
							autoComplete: {
								relationship: 'plan',
								endpoint: '/service/plan',
								quick: true,
								size: 10,
								searchField: 'name',
								parseKey: (row: Plan) => row.id,
								parseDisplay: (row: Plan) => row.name,
							},
						} as CRUDFormFieldAutoCompleteLegacy<Subscription, Plan>,
						{
							property: 'client_id',
							label: 'Client',
							proportion: 'w-1/2',
							autoComplete: {
								relationship: 'client',
								endpoint: '/client',
								searchField: 'name',
								parseKey: (row: Client) => row.id,
								parseDisplay: (row: Client) => row.name,
							},
						} as CRUDFormFieldAutoCompleteLegacy<Subscription, Client>,
						{
							property: 'due_day',
							label: 'Due date',
							proportion: 'w-1/3',
						},
					],
				},
			],
		},
		{
			key: 'connectivity-data',
			title: 'Connectivity',
			rows: [
				{
					key: 'row-1',
					fields: [
						{
							property: 'infra_nas_id',
							label: 'NAS',
							proportion: 'w-3/12',
							autoComplete: {
								relationship: {
									resolveValue: (form: Subscription) => {
										return form.infra_nas_pool?.infra_nas;
									},
									select: (selected: NAS, key: string) => {
										form.infra_nas_pool = {
											id: '',
											name: '',
											infra_nas: selected,
											infra_nas_id: key,
										};
										form.infra_nas_pool_id = '';

										setForm({ ...form });
									},
								},
								endpoint: '/infra/nas',
								quick: true,
								size: 10,
								searchField: 'name',
								parseKey: (row: NAS) => row.id,
								parseDisplay: (row: NAS) => `${row.name} (${row.router_src_address})`,
							},
						} as CRUDFormFieldAutoCompleteLegacy<Subscription, NAS>,
						{
							property: 'infra_nas_pool_id',
							disabled: !form.infra_nas_pool?.infra_nas || !pools || pools.length === 0,
							placeholder: form.infra_nas_pool?.infra_nas
								? pools && pools.length > 0
									? 'Select a pool'
									: `${form.infra_nas_pool?.infra_nas?.name} has no pools.`
								: 'Setup NAS to load pools',
							label: 'NAS/Pool',
							proportion: 'w-3/12',
							options: pools?.map((pool) => ({ key: pool.id, label: pool.name })),
						},
						{
							property: 'connectivity_user',
							label: 'Username (PPPoE Client)',
							proportion: 'w-2/12',
						},
						{
							property: 'connectivity_password',
							placeholder: 'Empty for suggested password',
							label: 'Password',
							proportion: 'w-2/12',
						},
						{
							property: 'connectivity_ip',
							label: 'IP',
							proportion: 'w-2/12',
						},
					],
				},
			],
		},
	];

	return groups;
}
