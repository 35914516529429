import React from 'react';
import { CRUDFormGroupType } from '../../components/CRUDFormGroupLegacy';
import CRUDFormPageLegacy from '../CRUDFormPageLegacy';
import { BeforeSubmitType } from '../../hooks/useCRUDFormPageLegacy';
import { Speed } from '../../shared/models/service/Speed';
import { Download, Upload } from 'lucide-react';

const groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>> = [
	{
		key: 'basic-data',
		title: 'Basic Data',
		rows: [
			{
				key: 'group-1',
				fields: [{ property: 'description', label: 'Description', proportion: 'w-full' }],
			},
		],
	},
	[
		{
			key: 'updown-data',
			title: 'Upload/Download (bps)',
			rows: [
				{
					key: 'group-1',
					fields: [
						{
							type: 'number',
							property: 'up_max_limit',
							label: 'Upload Max',
							proportion: 'w-1/2',
							icon: { type: Upload, color: 'text-primary' },
						},
						{
							type: 'number',
							property: 'down_max_limit',
							label: 'Download Max',
							proportion: 'w-1/2',
							icon: { type: Download, color: 'text-primary' },
						},
					],
				},
			],
		},
		{
			key: 'burst-data',
			title: 'Burst (bps)',
			rows: [
				{
					key: 'group-1',
					fields: [
						{
							type: 'number',
							property: 'up_burst',
							label: 'Upload',
							proportion: 'w-1/2',
							icon: { type: Upload, color: 'text-primary' },
						},
						{
							type: 'number',
							property: 'down_burst',
							label: 'Download',
							proportion: 'w-1/2',
							icon: { type: Download, color: 'text-primary' },
						},
					],
				},
			],
		},
		{
			key: 'threshold-data',
			title: 'Threshold (bps)',
			rows: [
				{
					key: 'group-1',
					fields: [
						{
							type: 'number',
							property: 'up_threshold',
							label: 'Upload',
							proportion: 'w-1/2',
							icon: { type: Upload, color: 'text-primary' },
						},
						{
							type: 'number',
							property: 'down_threshold',
							label: 'Download',
							proportion: 'w-1/2',
							icon: { type: Download, color: 'text-primary' },
						},
					],
				},
			],
		},
	],
	[
		{
			key: 'time-data',
			title: 'Time Limit (secs)',
			rows: [
				{
					key: 'group-1',
					fields: [
						{
							type: 'number',
							property: 'up_time_secs',
							label: 'Upload',
							proportion: 'w-1/2',
							icon: { type: Upload, color: 'text-primary' },
						},
						{
							type: 'number',
							property: 'down_time_secs',
							label: 'Download',
							proportion: 'w-1/2',
							icon: { type: Download, color: 'text-primary' },
						},
					],
				},
			],
		},
		{
			key: 'limit-data',
			title: 'Limit (bps)',
			rows: [
				{
					key: 'group-1',
					fields: [
						{
							type: 'number',
							property: 'up_limit_at',
							label: 'Upload',
							proportion: 'w-1/2',
							icon: { type: Upload, color: 'text-primary' },
						},
						{
							type: 'number',
							property: 'down_limit_at',
							label: 'Download',
							proportion: 'w-1/2',
							icon: { type: Download, color: 'text-primary' },
						},
					],
				},
			],
		},
		{
			key: 'priority-data',
			title: 'Priority (int)',
			rows: [
				{
					key: 'group-1',
					fields: [
						{
							type: 'number',
							property: 'up_priority',
							label: 'Upload',
							proportion: 'w-1/2',
							icon: { type: Upload, color: 'text-primary' },
						},
						{
							type: 'number',
							property: 'down_priority',
							label: 'Download',
							proportion: 'w-1/2',
							icon: { type: Download, color: 'text-primary' },
						},
					],
				},
			],
		},
	],
];

function SpeedByIdPage() {
	function handleBeforeSubmit(payload: BeforeSubmitType, form: Speed): BeforeSubmitType {
		payload.up_max_limit = parseInt((form.up_max_limit || '0').toString());
		payload.up_burst = parseInt((form.up_burst || '0').toString());
		payload.up_threshold = parseInt((form.up_threshold || '0').toString());
		payload.up_time_secs = parseInt((form.up_time_secs || '0').toString());
		payload.up_limit_at = parseInt((form.up_limit_at || '0').toString());
		payload.up_priority = parseInt((form.up_priority || '0').toString());
		payload.down_max_limit = parseInt((form.down_max_limit || '0').toString());
		payload.down_burst = parseInt((form.down_burst || '0').toString());
		payload.down_threshold = parseInt((form.down_threshold || '0').toString());
		payload.down_time_secs = parseInt((form.down_time_secs || '0').toString());
		payload.down_limit_at = parseInt((form.down_limit_at || '0').toString());
		payload.down_priority = parseInt((form.down_priority || '0').toString());

		if (!payload.up_limit_at) payload.up_limit_at = 0;
		if (!payload.up_limit_at) payload.down_limit_at = 0;
		if (!payload.up_limit_at) payload.up_priority = 0;
		if (!payload.up_limit_at) payload.down_priority = 0;

		return payload;
	}

	return (
		<CRUDFormPageLegacy
			title="Speed"
			groups={groups}
			endpoint="/service/speed"
			backwardUrl="/service/speeds"
			createUrl="/service/speeds/new"
			onBeforeSubmit={handleBeforeSubmit}
		/>
	);
}

export default SpeedByIdPage;
