import { useCallback, useEffect, useState } from 'react';
import { useForm as useHookForm, SubmitHandler, DefaultValues, FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { instanceToPlain, plainToClass } from 'class-transformer';

import useCRUDOperations from './api/useCRUDOperations';
import { BottomControlSaveEvent } from '../slices/BottomControl';

interface UseFormProps<TInput extends FieldValues = FieldValues> {
	endpoint: string;
	createUrl: string;
	onCreate?: (id: string) => void;
	defaultValues: DefaultValues<TInput> | DefaultValues<TInput>;
	classConstructor: new (...args: unknown[]) => TInput;
	onLoad?: (input: TInput) => TInput;
}

export function useForm<TInput extends FieldValues = FieldValues>({
	endpoint,
	createUrl,
	onCreate,
	defaultValues,
	classConstructor,
	onLoad,
}: UseFormProps<TInput>) {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const [original, setOriginal] = useState<TInput>();
	const [loaded, setLoaded] = useState(false);
	const { get, add, update, loading, error, clearError, ready } = useCRUDOperations(endpoint);

	const [staticDefaultValues] = useState(JSON.parse(JSON.stringify(defaultValues)));

	const form = useHookForm<TInput>({
		mode: 'onChange',
		defaultValues: staticDefaultValues,
	});

	const { handleSubmit, reset } = form;

	const isCreate = !id || id === 'new';

	// Handle form submission
	const onSubmit: SubmitHandler<TInput> = async (form): Promise<boolean> => {
		const classInstance = plainToClass(classConstructor, form);
		// Serialize only exposed fields
		const serialized = instanceToPlain(classInstance, { excludeExtraneousValues: true });

		if (isCreate) {
			const result = await add(serialized);
			if (!result) return false;

			reset(result);

			if (onCreate) {
				onCreate(result.id);
			} else {
				navigate(createUrl.replace('/new', `/${result.id}`));
			}
		} else {
			const result = await update(id!, serialized);
			if (!result) return false;
		}

		return true;
	};

	// Save handler based on BottomControlSaveEvent
	const handleSave = async (event: BottomControlSaveEvent): Promise<boolean> => {
		return new Promise<boolean>((resolve) => {
			function handleInvalid() {
				resolve(false);
			}

			async function handleValid(data: TInput) {
				const result = await onSubmit(data);
				if (!result) {
					resolve(false);
					return;
				}

				if (isCreate && event === 'save/create') {
					reset(staticDefaultValues);
				}
				resolve(true);
			}

			const submitHandler = handleSubmit(handleValid, handleInvalid);
			submitHandler();
		});
	};

	const load = useCallback(() => {
		if (!id || id === 'new') {
			reset(staticDefaultValues);
			setOriginal(undefined);
			return;
		}

		if (ready) {
			get(id).then((data) => {
				if (onLoad) {
					data = onLoad(data);
				}
				reset(data);
				setOriginal(data);
				setLoaded(true);
			});
		}
	}, [id, ready, get, reset]);

	useEffect(() => {
		load();
	}, [load]);

	return {
		id,
		original,
		isCreate,
		loading,
		error,
		clearError,
		handleSave,
		form,
		reload: load,
		loaded,
	};
}
