import React from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroupLegacy';
import { useCRUDFormPageLegacy } from '../hooks/useCRUDFormPageLegacy';
import { BeforeSubmitType } from '../hooks/useCRUDFormPageLegacy';
import GenericCRUDFormPageLegacy from './GenericCRUDFormPageLegacy';

export interface CRUDFormPageProps<T> {
	title: string;
	groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>>;
	backwardUrl: string;
	createUrl: string;
	endpoint: string;
	onAfterFetch?: (data: T) => object;
	onBeforeSubmit?: (payload: BeforeSubmitType, form: T) => BeforeSubmitType;
	children?: React.ReactNode;
}

function CRUDFormPageLegacy<T>({
	title,
	groups,
	backwardUrl,
	createUrl,
	endpoint,
	onAfterFetch,
	onBeforeSubmit,
	children,
}: CRUDFormPageProps<T>) {
	const { form, setForm, handleSave, loading, error, clearError } = useCRUDFormPageLegacy(
		endpoint,
		groups,
		createUrl,
		onAfterFetch,
		onBeforeSubmit,
	);

	return (
		<GenericCRUDFormPageLegacy
			title={title}
			groups={groups}
			backwardUrl={backwardUrl}
			createUrl={createUrl}
			form={form as never}
			setForm={setForm}
			loading={loading}
			handleSave={handleSave}
			error={error}
			onClearError={clearError}
		>
			{children}
		</GenericCRUDFormPageLegacy>
	);
}

export default CRUDFormPageLegacy;
