import React, { ChangeEvent, FocusEvent, forwardRef, HTMLInputTypeAttribute, KeyboardEvent, useState } from 'react';
import { generateRandomId, toLocaleNumberString } from '../shared/utils';
import { LucideIcon } from 'lucide-react';
import Icon from './Icon';

export interface InputIcon {
	type: LucideIcon;
	color: string;
}

export interface InputSpinningIcon extends InputIcon {
	spin?: boolean;
}

export interface InputActionIcon extends InputIcon {
	action?: () => void;
}

export function applyMask(inputValue: string, locale: string = navigator.language, decimalDigits: number = 2): string {
	// Remove any non-numeric characters except the decimal point and minus sign
	const cleanedInput = inputValue.replace(/[^\d]/g, '');

	// Ensure the input is treated as a decimal number
	const numberAsDecimal = cleanedInput.padStart(decimalDigits + 1, '0'); // Pad the input with leading zeros
	const integerPart = numberAsDecimal.slice(0, -decimalDigits);
	const decimalPart = numberAsDecimal.slice(-decimalDigits);

	// Combine the integer and decimal parts
	const rawNumber = parseFloat(`${integerPart}.${decimalPart}`);
	const formattedNumber = toLocaleNumberString(rawNumber, locale, decimalDigits);

	return formattedNumber;
}

export function undoMask(
	formattedValue: string,
	locale: string = navigator.language,
	decimalPlaces: number = 2,
): number | undefined {
	// Use Intl.NumberFormat to parse the formatted value according to the locale
	const numberFormat = new Intl.NumberFormat(locale);
	const parts = numberFormat.formatToParts(12345.6789);

	// Identify the decimal and group separator characters
	const decimalSeparator = parts.find((part) => part.type === 'decimal')?.value || '.';
	const groupSeparator = parts.find((part) => part.type === 'group')?.value || ',';

	// Remove group separators and replace the decimal separator with a dot
	let numericString = formattedValue.split(groupSeparator).join('');
	numericString = numericString.split(decimalSeparator).join('.');

	// Parse the string back to a float
	const parsedNumber = parseFloat(numericString);

	// If the parsed number is not a number, return an empty string
	if (isNaN(parsedNumber)) {
		return;
	}

	// Convert the number to a string with fixed decimal places
	const numberAsString = parsedNumber.toFixed(decimalPlaces);

	// Remove the decimal point to get the original value
	const originalValue = numberAsString.replace('.', '');

	return parseFloat(originalValue) || undefined;
}

export interface InputProps {
	type?: HTMLInputTypeAttribute;
	mask?: 'decimal';
	multiline?: boolean;
	label?: string;
	value?: string;
	placeholder?: string;
	inputRef?: React.LegacyRef<HTMLInputElement> | React.LegacyRef<HTMLTextAreaElement>;
	onChange?: (text: string) => void;
	onKeyDown?: ((e: KeyboardEvent<HTMLInputElement>) => void) | ((e: KeyboardEvent<HTMLTextAreaElement>) => void);
	onFocus?: ((e: FocusEvent<HTMLInputElement>) => void) | ((e: FocusEvent<HTMLTextAreaElement>) => void);
	onBlur?: ((e: FocusEvent<HTMLInputElement>) => void) | ((e: FocusEvent<HTMLTextAreaElement>) => void);
	autoComplete?: boolean;
	maxLength?: number;
	highlight?: boolean;
	condensed?: boolean;
	rounded?: boolean;
	disabled?: boolean;
	disposition?: 'row' | 'column';
	contentIcon?: InputActionIcon;
	noContentIcon?: InputIcon;
	icon?: InputSpinningIcon;
	full?: boolean;
}

const Input = forwardRef(function ForwardedInput(
	props: InputProps,
	ref: React.LegacyRef<HTMLInputElement> | React.LegacyRef<HTMLTextAreaElement>,
) {
	// Pass the ref to the inner component
	return <InputComponent {...props} inputRef={ref} />;
}) as (
	props: InputProps & { ref?: React.LegacyRef<HTMLInputElement> | React.LegacyRef<HTMLTextAreaElement> },
) => ReturnType<typeof InputComponent>;

export default Input;

function InputComponent({
	type = 'text',
	mask,
	multiline,
	label,
	highlight,
	value,
	onChange,
	onKeyDown,
	onFocus,
	onBlur,
	placeholder,
	inputRef,
	autoComplete,
	maxLength,
	condensed,
	rounded,
	disabled,
	disposition,
	contentIcon,
	noContentIcon,
	icon,
	full,
}: InputProps) {
	const [id] = useState(generateRandomId(16));

	function input_handleChange(e: ChangeEvent<HTMLInputElement>) {
		const { value: inputValue, checked } = e.target;
		let value = inputValue;

		if (type === 'number' && mask) {
			value = applyMask(value);
		}

		if (type === 'checkbox') {
			value = `${checked}`;
		}

		onChange && onChange(value);
	}

	function textarea_handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
		onChange && onChange(e.target.value);
	}

	if (multiline && type !== 'text') throw new Error('Multiline cannot be different then type text.');

	const iconClasses = contentIcon || noContentIcon || icon ? 'ps-4 pe-8' : 'px-4';
	const condensedClasses = condensed ? 'h-10 text-sm' : 'py-2 h-12';
	const roundedClasses = rounded ? 'rounded-full' : 'rounded-md';

	const iconsClasses = 'w-9 h-9 px-2 m-auto';
	const disabledClasses = disabled ? 'cursor-not-allowed text-light-extra' : '';

	const fullClasses = full && ['checkbox'].indexOf(type) === -1 ? 'w-full' : '';

	const typeWrapperClasses =
		['checkbox'].indexOf(type) > -1 ? 'flex-row-reverse justify-start items-start accent-primary' : '';
	const typeInputClasses = ['checkbox'].indexOf(type) > -1 ? 'h-4 w-4' : '';

	return (
		<div
			className={`relative flex ${disposition === 'row' ? 'flex-row items-center gap-2' : 'flex-col'} ${fullClasses} ${typeWrapperClasses}`}
		>
			{label && (
				<label htmlFor={id}>
					<span className={`${highlight && 'font-bold'} text-sm cursor-pointer`}>{label}</span>
				</label>
			)}
			<div className={'relative flex flex-1 md:flex-row'}>
				{multiline ? (
					<textarea
						id={id}
						ref={inputRef as React.LegacyRef<HTMLTextAreaElement>}
						className={`border-gray-300 text-sm border-[1px] ${iconClasses} ${condensedClasses} ${roundedClasses} ${disabledClasses} w-full outline-none placeholder:text-[#A0A0A0] focus:border-primary-600 h-auto`}
						placeholder={placeholder}
						value={value}
						onChange={textarea_handleChange}
						onKeyDown={onKeyDown as (e: KeyboardEvent<HTMLTextAreaElement>) => void}
						onFocus={onFocus as (e: FocusEvent<HTMLTextAreaElement>) => void}
						onBlur={onBlur as (e: FocusEvent<HTMLTextAreaElement>) => void}
						maxLength={maxLength}
						disabled={disabled}
					/>
				) : (
					<input
						id={id}
						type={type === 'number' && mask ? 'text' : type}
						ref={inputRef as React.LegacyRef<HTMLInputElement>}
						className={`border-gray-300 text-sm border-[1px] ${iconClasses} ${condensedClasses} ${roundedClasses} ${disabledClasses} ${typeInputClasses} w-full outline-none placeholder:text-[#A0A0A0] focus:border-primary-600`}
						placeholder={placeholder}
						value={type !== 'checkbox' ? value : undefined}
						onChange={input_handleChange}
						onKeyDown={onKeyDown as (e: KeyboardEvent<HTMLInputElement>) => void}
						onFocus={onFocus as (e: FocusEvent<HTMLInputElement>) => void}
						onBlur={onBlur as (e: FocusEvent<HTMLInputElement>) => void}
						maxLength={maxLength}
						autoComplete={autoComplete ? 'on' : 'off'}
						disabled={disabled}
						checked={
							type === 'checkbox' ? (typeof value === 'boolean' ? value : value === 'true') : undefined
						}
					/>
				)}
				{contentIcon && value && !icon && (
					<Icon
						icon={contentIcon.type}
						className={`absolute ${contentIcon.color} ${iconsClasses} inset-y-0 right-0 flex items-center cursor-pointer`}
						onClick={contentIcon.action}
					/>
				)}
				{noContentIcon && !value && !icon && (
					<Icon
						icon={noContentIcon.type}
						className={`absolute ${noContentIcon.color} ${iconsClasses} inset-y-0 right-0 flex items-center cursor-pointer`}
					/>
				)}
				{icon && (
					<Icon
						icon={icon.type}
						className={`absolute ${icon.color} ${iconsClasses} inset-y-0 right-0 flex items-center cursor-pointer ${icon.spin ? 'animate-spin' : ''}`}
					/>
				)}
			</div>
		</div>
	);
}
