import React, { forwardRef, Ref, useEffect, useState } from 'react';
import Input, { InputActionIcon } from './Input';
import AutoComplete, { AutoCompleteItem } from './AutoComplete';
import usePaginatedQuery from '../hooks/api/usePaginatedQuery';
import { PrismaCondition, PrismaWhereClause } from '../hooks/api/query';
import { LoaderCircle, X } from 'lucide-react';

export interface InputAutoCompleteProps<T> {
	inputRef?: Ref<HTMLInputElement>;
	field: {
		property: string;
		label: string;
		placeholder?: string;
		highlight?: boolean;
	};
	value: T;
	initialValue?: T;
	endpoint: string;
	searchField: string;
	parseKey: (row: T) => string;
	parseDisplay: (row: T) => string;
	onSelect: (selected: T, selected_key: string) => void;
	onClear: () => void;
	actionIcon?: InputActionIcon;
	size?: number;
	quick?: boolean;
}

const InputAutoComplete = forwardRef(function ForwardedInputAutoComplete<T>(
	props: InputAutoCompleteProps<T>,
	ref: Ref<HTMLInputElement>,
) {
	// Pass the ref to the inner component
	return <InputAutoCompleteComponent<T> {...props} inputRef={ref} />;
}) as <T>(
	props: InputAutoCompleteProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReturnType<typeof InputAutoCompleteComponent>;

export default InputAutoComplete;

function InputAutoCompleteComponent<T>({
	inputRef,
	field,
	value,
	endpoint,
	searchField,
	parseKey,
	parseDisplay,
	onSelect,
	onClear,
	actionIcon,
	size = 5,
	quick = false,
}: InputAutoCompleteProps<T>) {
	const [query, setQuery] = useState<string>('');
	const [where, setWhere] = useState<PrismaWhereClause>();
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const { response, loading } = usePaginatedQuery<T>(endpoint, {
		initialSortField: searchField,
		where,
		initialPage: 1,
		initialPageSize: size,
		initialSortOrder: 'asc',
		performFetch: isFocused,
		emptyQueryFetch: quick,
	});

	useEffect(() => {
		if (!query && !quick) return;
		setWhere({ [searchField]: { contains: query } as PrismaCondition } as PrismaWhereClause);
	}, [query]);

	function handleAutoCompleteQuery(value: string) {
		setQuery(value);
	}

	function handleAutoCompleteSelect(item: AutoCompleteItem) {
		const selected = response?.rows.find((row) => parseKey(row) === item.key);
		if (!selected) return;

		onSelect(selected, item.key);
		setQuery('');
	}

	function handleAutoCompleteClear() {
		onClear();
		setQuery('');
	}

	function handleFocus() {
		setIsFocused(true);
	}

	function handleBlur() {
		setIsFocused(false);
	}

	const actionIconToUse =
		actionIcon || (value ? { type: X, color: 'text-danger', action: handleAutoCompleteClear } : undefined);

	return (
		<div className="relative" onBlur={handleBlur}>
			<Input
				ref={inputRef}
				key={field.property}
				label={field.label}
				placeholder={field.placeholder || 'Start typing to refine records'}
				highlight={field.highlight}
				onFocus={handleFocus}
				value={query || (value && parseDisplay(value)) || ''}
				onChange={(event) => handleAutoCompleteQuery(event)}
				icon={loading ? { type: LoaderCircle, color: 'text-primary', spin: true } : undefined}
				contentIcon={actionIconToUse}
				full
			/>
			{response && isFocused && (
				<AutoComplete
					items={response.rows.map((item) => ({
						key: parseKey(item),
						display: parseDisplay(item),
					}))}
					onSelect={handleAutoCompleteSelect}
				/>
			)}
		</div>
	);
}
