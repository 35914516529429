import React from 'react';

import PaginatedTablePageLegacy, { PaginatedTableItem } from './PaginatedTablePageLegacy';
import usePaginatedTablePage from '../hooks/usePaginatedTablePage';
import { DataTableField, DataTableAction } from '../components/DataTable';
import { Filter } from '../slices/Filters';

export interface PaginatedPageProps<T> {
	title: string;
	endpoint: string;
	formUrl: string;
	initialSortField: string;
	fields: Array<DataTableField<T>>;
	actions?: DataTableAction<T>[];
	filters?: Array<Filter>;
	useDefaultActions?: boolean;
	usePagination?: boolean;
	useSorting?: boolean;
}

export default function PaginatedPageLegacy<T extends PaginatedTableItem>({
	title,
	endpoint,
	formUrl,
	initialSortField,
	fields,
	actions,
	filters,
	useDefaultActions,
	usePagination = true,
	useSorting = true,
}: PaginatedPageProps<T>) {
	const {
		data,
		error,
		loading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleRemove,
		handleClearError,
		handleFilterChange,
	} = usePaginatedTablePage<T>({
		endpoint,
		initialSortField,
		filters,
		usePagination,
		useSorting,
	});

	function handleOnRemove(id: string) {
		handleRemove(id);
	}

	return (
		<PaginatedTablePageLegacy
			title={title}
			filters={filters}
			filterValues={filterValues}
			data={data}
			fields={fields}
			actions={actions}
			error={error}
			loading={loading}
			page={page}
			pageSize={pageSize}
			totalRecords={totalRecords}
			sortField={sortField}
			sortOrder={sortOrder}
			formUrl={formUrl}
			useDefaultActions={useDefaultActions}
			onPageSize={handleOnPageSize}
			onPage={handleOnPage}
			onRefresh={handleOnRefresh}
			onSort={handleOnSort}
			onFilterChange={handleFilterChange}
			onRemove={handleOnRemove}
			onClearError={handleClearError}
		/>
	);
}
