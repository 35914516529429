import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Input from '@/components/Input';
import useAPIVPN from '@/hooks/api/useAPIVPN';
import { APIErrorClass } from '@/shared/api_errors';
import AlertBox from '@/slices/AlertBox';
import FormGroup from '@/slices/FormGroup';
import { AlertCircle, GlobeLock } from 'lucide-react';
import React, { useState } from 'react';

interface NoVPNProps {
	nasId: string;
	onNASChanged: () => void;
}

export default function NoVPN({ nasId, onNASChanged }: NoVPNProps) {
	const { addToVPN, error, clearError } = useAPIVPN();
	const [publicKey, setPublicKey] = useState<string>('');

	async function handleAddToVPN() {
		if (!publicKey) return;

		const result = await addToVPN({
			nas_id: nasId,
			public_key: publicKey,
		});

		if (!result) return;
		onNASChanged();
	}

	return (
		<FormGroup
			title="VPN Setup - Step 1/2"
			grow
			action={
				<span className="flex gap-2 items-center text-nowrap text-sm">
					The NAS is not set up to connect to Gravity VPN
					<Icon icon={GlobeLock} className="text-warning" />
				</span>
			}
		>
			{error && (
				<AlertBox
					type={error.error_type() === APIErrorClass.InternalError ? 'danger' : 'warning'}
					title={
						error.error_type() === APIErrorClass.InternalError
							? 'Ops! Something went wrong.'
							: 'Oh, no! Validation errors found.'
					}
					message={
						error.error_type() === APIErrorClass.InternalError
							? 'An internal problem occurred and it was not possible to process your request.'
							: [error.error, ...(error.message ? error.message : [])].join(', ')
					}
					onOk={clearError}
				/>
			)}
			<div className="flex flex-col gap-4">
				<p className="font-semibold">
					<span className="flex gap-2 items-center">
						<Icon icon={AlertCircle} className="text-info" />
						To integrate your NAS with the Gravity VPN, please follow these simple steps:
					</span>
				</p>
				<ul className="text-sm list-decimal ml-6 flex flex-col gap-4">
					<li>
						<div className="flex flex-col gap-2 items-start">
							<p>
								Please copy and paste the code below to set up the WireGuard interface on your router.
							</p>
							<textarea
								className="font-mono text-sm bg-gray-200 resize-none h-16 p-2 rounded-md w-full outline-none cursor-pointer"
								onClick={(event) => (event.target as unknown as HTMLTextAreaElement).select()}
								value={
									'/interface wireguard add name=GRAVITY-VPN\n:put [/interface wireguard get [find name="GRAVITY-VPN"] public-key]\n'
								}
								readOnly
							/>
						</div>
					</li>
					<li>
						<div className="flex flex-col gap-2 items-start">
							<p>
								Please copy and paste the public key that is displayed and paste it into the{' '}
								<b>{'"Public Key"'}</b>&nbsp; field below.
							</p>
							<div className="min-w-96">
								<Input
									placeholder="NGhcPTe8u1W2VDPsEyrd336oyJ2dl+ao+kW6aMKhAis="
									value={publicKey}
									onChange={(value) => setPublicKey(value)}
								/>
							</div>
							<Button text="Save Public Key" disabled={!publicKey} onClick={handleAddToVPN} />
						</div>
					</li>
				</ul>
			</div>
		</FormGroup>
	);
}
