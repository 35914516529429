import React, { useState } from 'react';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import useAPIVPN from '@/hooks/api/useAPIVPN';
import { APIErrorClass } from '@/shared/api_errors';
import AlertBox from '@/slices/AlertBox';
import FormGroup from '@/slices/FormGroup';
import { EthernetPort, LoaderCircle, TriangleAlert } from 'lucide-react';
import { useEffect } from 'react';

interface ValidatedVPNProps {
	nasId: string;
	nasVpnIp: string;
	onNASChanged: () => void;
}

export default function ValidatedVPN({ nasId, nasVpnIp, onNASChanged }: ValidatedVPNProps) {
	const {
		vpnParameters,
		getVPNParameters,
		testVPNConnection,
		vpnTest,
		removeFromVPN,
		clearVPNTest,
		vpnLoading,
		error,
		clearError,
	} = useAPIVPN();
	const [vpnTestError, setVPNTestError] = useState('');

	useEffect(() => {
		if (vpnParameters) return;
		getVPNParameters();
	}, [vpnParameters]);

	useEffect(() => {
		if (!vpnTest) return;
		if (!vpnTest.error) return;
		setVPNTestError(vpnTest.error);
	}, [vpnTest]);

	async function handleTestConnection() {
		setVPNTestError('');
		const result = await testVPNConnection(nasId);
		if (!result) return;
		onNASChanged();
	}

	async function handleRemoveVPN() {
		const result = await removeFromVPN(nasId);
		if (!result) return;
		onNASChanged();
	}

	return (
		<FormGroup
			title="VPN Setup - Step 2/2"
			grow
			action={
				<div className="flex flex-col items-end">
					<span className="flex gap-2 items-center text-nowrap text-sm">
						Currently, the NAS is configured for VPN, but it is not yet connected.
						<Icon icon={EthernetPort} className="text-warning animate-pulse" />
					</span>
					<Button text="Remove VPN configuration" variant="link" onClick={handleRemoveVPN} />
				</div>
			}
		>
			{error && (
				<AlertBox
					type={error.error_type() === APIErrorClass.InternalError ? 'danger' : 'warning'}
					title={
						error.error_type() === APIErrorClass.InternalError
							? 'Ops! Something went wrong.'
							: 'Oh, no! Validation errors found.'
					}
					message={
						error.error_type() === APIErrorClass.InternalError
							? 'An internal problem occurred and it was not possible to process your request.'
							: [error.error, ...(error.message ? error.message : [])].join(', ')
					}
					onOk={clearError}
				/>
			)}
			{vpnTest?.error && (
				<AlertBox
					type="warning"
					title="VPN Connection Test Failed"
					message={vpnTest.error}
					onOk={clearVPNTest}
				/>
			)}
			<div className={`flex flex-col gap-4 relative ${!vpnParameters ? 'opacity-25 animate-pulse' : ''}`}>
				<ul className="text-sm list-decimal ml-6 flex flex-col gap-4">
					<li>
						<div className="flex flex-col gap-2 items-start">
							<p>Please copy and paste the code below to set up the WireGuard peer on your router.</p>
							<textarea
								className="font-mono text-sm bg-gray-200 resize-none h-24 p-2 rounded-md w-full outline-none cursor-pointer"
								onClick={(event) => (event.target as unknown as HTMLTextAreaElement).select()}
								value={
									vpnParameters
										? `/interface wireguard peers add allowed-address=${vpnParameters.ipcidr} endpoint-address=${vpnParameters.host} endpoint-port=${vpnParameters.port} interface=GRAVITY-VPN public-key="${vpnParameters.public_key}" persistent-keepalive=25s\n/ip address add address=${nasVpnIp}/${vpnParameters.ipcidr.split('/').pop()} interface=GRAVITY-VPN\n`
										: 'Loading command...'
								}
								readOnly
							/>
						</div>
					</li>
					<li>
						<div className="flex flex-col gap-2 items-start">
							<p>Click on the button below to test the connection</p>
							<div className="flex gap-2 items-center">
								<Button
									text="Test Connection"
									icon={vpnLoading ? LoaderCircle : undefined}
									iconSpin={vpnLoading}
									disabled={vpnLoading}
									onClick={handleTestConnection}
								/>
								{vpnTestError && vpnTestError.indexOf('Socket timeout') > -1 && (
									<div className="flex flex-col items-end">
										<span className="flex gap-2 items-center text-nowrap text-sm">
											<Icon icon={TriangleAlert} className="text-warning" />
											<strong>Firewall rules</strong> on your router OS may be blocking the
											connection. Please check and allow the connection.
										</span>
									</div>
								)}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</FormGroup>
	);
}
