import usePaginatedQuery, { SortOrder } from './api/usePaginatedQuery';
import useCRUDOperations from './api/useCRUDOperations';
import { APIError } from '../shared/api_errors';
import { PrismaCondition, PrismaWhereClause } from './api/query';
import { useEffect, useState } from 'react';
import { FieldOperator, FieldOperatorResolver, Filter, FilterValues, isOr } from '../slices/Filters';

export interface PaginatedTablePageProps {
	endpoint: string;
	initialSortField?: string;
	initialSortOrder?: SortOrder;
	filters?: Array<Filter>;
	fixedWhere?: PrismaWhereClause;
	usePagination?: boolean;
	useSorting?: boolean;
	emptyQueryFetch?: boolean;
}

export interface PaginatedTablePageResult<T> {
	data: T[];
	fields: Array<{ field: keyof T; title: string; sortable?: boolean }>;
	actions: Array<{ title: string; icon: string; action: (row: T) => void }>;
	error?: APIError;
	loading: boolean;
	page: number;
	pageSize: number;
	totalRecords: number;
	sortField?: string;
	sortOrder: SortOrder;
	filterValues: FilterValues;
	refetch: () => Promise<void>;
	handleOnPageSize: (pageSize: number) => void;
	handleOnPage: (page: number) => void;
	handleOnRefresh: () => void;
	handleOnSort: (newSortField: string, newSortOrder: SortOrder) => void;
	handleRemove: (id: string) => void;
	handleClearError: () => void;
	handleFilterChange: (filter: Filter, value?: string) => void;
}

function toWhereClause(filter: Filter, value?: string) {
	const fieldOperator = filter.operator as FieldOperator;
	const where: PrismaWhereClause = {}; // Local accumulator for the current filter

	if (fieldOperator.field === undefined) {
		const fieldOperatorResolver = filter.operator as FieldOperatorResolver;
		const resolvedFieldOperators = fieldOperatorResolver(value);

		if (resolvedFieldOperators) {
			if (isOr(resolvedFieldOperators)) {
				// Accumulate OR conditions for the current filter
				where.OR = resolvedFieldOperators.operators.map((op) => ({
					[op.field]: {
						[op.op]: op.value,
					} as PrismaCondition,
				})) as PrismaWhereClause[];
			} else {
				// Accumulate AND conditions for the current filter
				where.AND = resolvedFieldOperators.map((resolvedFieldOperator) => {
					const { field, op, value } = resolvedFieldOperator;
					return {
						[field]: {
							[op]: value,
						} as PrismaCondition,
					};
				}) as PrismaWhereClause[];
			}
		}
	} else {
		// Single AND condition for the current filter
		where.AND = [
			{
				[fieldOperator.field]: {
					[fieldOperator.op]: value,
				} as PrismaCondition,
			},
		];
	}

	return where;
}

export function mergePrismaWhereClauses(...clauses: PrismaWhereClause[]): PrismaWhereClause {
	const mergedClause: PrismaWhereClause = {};

	clauses.forEach((clause) => {
		Object.entries(clause).forEach(([key, value]) => {
			if (key === 'OR' || key === 'AND' || key === 'NOT') {
				// Ensure both `mergedClause[key]` and `value` are PrismaWhereClause[]
				if (!Array.isArray(mergedClause[key])) {
					mergedClause[key] = [];
				}

				// Only merge if `value` is an array of PrismaWhereClause
				if (Array.isArray(value) && value.every((item) => typeof item === 'object' && item !== null)) {
					mergedClause[key] = [...(mergedClause[key] as PrismaWhereClause[]), ...value];
				}
			} else {
				// Merge key-value pairs directly into the root
				mergedClause[key] = value;
			}
		});
	});

	return mergedClause;
}

export default function usePaginatedTablePage<T extends { id: string }>({
	endpoint,
	initialSortField,
	initialSortOrder = 'asc',
	filters,
	fixedWhere,
	usePagination = true,
	useSorting = true,
	emptyQueryFetch = true,
}: PaginatedTablePageProps): PaginatedTablePageResult<T> {
	const [filterValues, setFilterValues] = useState<FilterValues>({});
	const [where, setWhere] = useState<PrismaWhereClause>(fixedWhere || {});

	const {
		error: queryError,
		refetch,
		setPageSize,
		setOrder,
		setPage,
		response,
		loading,
		page,
		pageSize,
		sortField,
		sortOrder,
	} = usePaginatedQuery<T>(endpoint, {
		initialSortField,
		initialSortOrder,
		where,
		usePagination,
		useSorting,
		emptyQueryFetch,
	});
	const { remove, error: crudOperationsError, clearError } = useCRUDOperations(endpoint);

	const error: APIError | undefined = queryError || crudOperationsError;

	useEffect(() => {
		if (!filters) return;
		for (const filter of filters) {
			if (filter.initialValue) {
				handleFilterChange(filter, filter.initialValue);
			}
		}
	}, []);

	useEffect(() => {
		const clauses: PrismaWhereClause[] = fixedWhere ? [fixedWhere] : [];
		for (const filterKey in filterValues) {
			const filter = filters?.find((filter) => filter.id === filterKey);
			if (!filter) continue;

			const value = filterValues[filterKey];
			const filterWhere = toWhereClause(filter, value);
			clauses.push(filterWhere);
		}

		const mergedWhere = mergePrismaWhereClauses(...clauses);
		setWhere(mergedWhere);
	}, [filterValues, fixedWhere]);

	function handleFilterChange(filter: Filter, value?: string) {
		setFilterValues({ ...filterValues, [filter.id]: value });
	}

	async function handleRemove(id: string) {
		await remove(id);
		refetch();
	}

	function handleClearError() {
		clearError();
		clearError();
	}

	function handleOnPageSize(pageSize: number) {
		setPageSize(pageSize);
	}

	function handleOnPage(page: number) {
		setPage(page);
	}

	function handleOnRefresh() {
		refetch();
	}

	function handleOnSort(newSortField: string, newSortOrder: SortOrder) {
		setOrder(newSortField, newSortOrder);
	}

	return {
		data: response ? response.rows : [],
		refetch: refetch,
		fields: [],
		actions: [],
		error,
		loading: loading,
		page: page,
		pageSize: pageSize,
		totalRecords: response ? response.records : 0,
		sortField: sortField,
		sortOrder: sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleRemove,
		handleClearError,
		handleFilterChange,
	};
}
