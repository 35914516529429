import React from 'react';

import WrapperPage from '../WrapperPage';
import useAPISAASAccount from '../../hooks/api/useAPISAASAccount';
import FormGroup from '../../slices/FormGroup';
import { applyMask } from '@/components/Input';
import { setDateWithHour, toLocaleLongDateString, toLocaleShortDateTimeString } from '@/shared/utils';
import DataTable from '@/components/DataTable';
import usePaginatedQuery, { PaginatedResponse } from '@/hooks/api/usePaginatedQuery';
import { SAASUsage } from '@/shared/models/saas/account/SaasUsage';
import { CheckCircle, LoaderCircle } from 'lucide-react';

const TOP_TIER = 'Top Tier';

export default function SubscriptionPage() {
	const { subscription, upcomingInvoice, loading, error } = useAPISAASAccount({
		mustFetchSubscription: true,
		mustFetchUpcomingInvoice: true,
	});

	const {
		response,
		loading: usageLoading,
		pageSize,
		page,
		sortOrder,
		sortField,
		setOrder,
		setPage,
		setPageSize,
		refetch,
	} = usePaginatedQuery<SAASUsage>('/saas/account/detailed-usage', {
		initialSortField: 'usage_date',
		initialSortOrder: 'desc',
	});
	const usage = !response ? ({ rows: [], records: 0 } as PaginatedResponse<SAASUsage>) : response;

	return (
		<WrapperPage title="Gravity Subscription" error={error}>
			<div className="m-4 flex flex-col gap-4">
				{loading && <LoaderCircle className="animate-spin" />}
				{subscription && (
					<>
						<FormGroup title="Status & Pricing">
							<div className="flex flex-col gap-2">
								<div className="flex flex-row gap-2">
									<CheckCircle
										className={`${subscription.status === 'active' ? 'text-success' : 'text-light-extra'}`}
									/>
									<span className="text-sm font-bold">
										{subscription.items.data[0]?.price.product.name}
									</span>
									<span className="text-sm capitalize">{subscription.status}</span>
								</div>
								<p>
									{upcomingInvoice && (
										<span className="text-sm">
											Subscribed at {toLocaleLongDateString(upcomingInvoice.start_date)}
										</span>
									)}
								</p>
								{upcomingInvoice && (
									<>
										<h2 className="text-sm font-bold">Subscription Tiers</h2>
										<DataTable
											style="condensed"
											fields={[
												{
													title: 'Active Subscriptions',
													property: 'up_to',
													extractor: (row) => (row.up_to ? `Up to ${row.up_to}` : TOP_TIER),
													align: 'center',
												},
												{
													title: '$ per hour',
													property: 'unit_amount',
													extractor: (row) => row.unit_amount.toFixed(12),
													align: 'center',
												},
												{
													title: '$ per month',
													property: 'unit_amount',
													extractor: (row) => (row.unit_amount * 730.56).toFixed(2),
													align: 'center',
												},
											]}
											rows={upcomingInvoice.tiers}
										/>
									</>
								)}
							</div>
						</FormGroup>
						<FormGroup title="Cost & Usage">
							{upcomingInvoice && (
								<div className="flex flex-col">
									<div className="flex gap-12">
										<div className="flex flex-col gap-2">
											<span className="text-sm font-bold">Current month:</span>
											<div className="flex gap-4 w-36 items-center justify-between">
												<div className="flex flex-col gap-[2px]">
													<span className="text-xs">Cost</span>
													<span className="text-lg font-bold text-success">
														${applyMask(upcomingInvoice.amount.toString())}
													</span>
												</div>
												<div className="flex flex-col gap-[2px]">
													<span className="text-xs">Units</span>
													<span className="text-lg font-bold text-info">
														{upcomingInvoice.quantity_used}
													</span>
												</div>
											</div>
										</div>
										<div className="flex flex-col gap-2">
											<span className="text-sm font-bold">Forecasted month:</span>
											<div className="flex gap-4 w-36 items-center justify-between">
												<div className="flex flex-col gap-[2px]">
													<span className="text-xs">Cost</span>
													<span className="text-lg font-bold text-light-extra">
														$
														{applyMask(
															upcomingInvoice.forecasted_total_amount?.toString() || '0',
														)}
													</span>
												</div>
												<div className="flex flex-col gap-[2px]">
													<span className="text-xs">Units</span>
													<span className="text-lg font-bold text-light-extra">
														{upcomingInvoice.forecasted_quantity}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</FormGroup>
						<FormGroup title="Detailed Usage">
							<DataTable
								fields={[
									{
										title: 'Date',
										property: 'usage_date',
										extractor: (row) =>
											`${toLocaleShortDateTimeString(setDateWithHour(row.usage_date, row.usage_hour))}`,
									},
									{
										title: 'Active Subscriptions',
										property: 'active_subscriptions',
									},
									{
										title: 'Tier',
										property: '',
										extractor: (row) => {
											const tier = upcomingInvoice?.tiers.find(
												(tier) => row.active_subscriptions < tier.up_to || !tier.up_to,
											);
											return tier ? (tier.up_to ? `Up to ${tier.up_to}` : TOP_TIER) : '';
										},
									},
									{
										title: 'Cost',
										property: '',
										extractor: (row) => {
											const tier = upcomingInvoice?.tiers.find(
												(tier) => row.active_subscriptions < tier.up_to || !tier.up_to,
											);
											return tier
												? `$${(tier.unit_amount * row.active_subscriptions).toFixed(12)}`
												: '';
										},
									},
								]}
								rows={usage.rows}
								loading={usageLoading}
								pages={{ page, size: pageSize, total: usage.records }}
								onPageSize={(pageSize) => setPageSize(pageSize)}
								onPage={(page) => setPage(page)}
								onRefresh={refetch}
								sort={sortField ? { field: sortField, order: sortOrder } : undefined}
								onSort={(sortField, sortOrder) => setOrder(sortField, sortOrder)}
							/>
						</FormGroup>
					</>
				)}
			</div>
		</WrapperPage>
	);
}
