import { useState, useCallback } from 'react';
import useAPI from './useAPI';
import { APIError } from '@/shared/api_errors';
import { useAuth } from '@/hooks/useAuth';
import { useProfile } from '@/hooks/useProfile';

export interface VPNAddPayload {
	nas_id: string;
	public_key: string;
}

export interface VPNTestResponse {
	success: boolean;
	error?: string;
	data?: string;
}

export interface VPNParametersResponse {
	ipcidr: string;
	host: string;
	port: string;
	public_key: string;
}

export default function useAPIVPN() {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const [vpnTest, setVPNTest] = useState<VPNTestResponse | undefined>(undefined);
	const [vpnParameters, setVPNParameters] = useState<VPNParametersResponse | undefined>(undefined);
	const [vpnLoading, setVPNLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	const addToVPN = useCallback(
		async (payload: VPNAddPayload) => {
			setVPNLoading(true);

			try {
				await api.put(`/infra/vpn/add/${payload.nas_id}`, { body: { public_key: payload.public_key } });
				setError(undefined);
				return true;
			} catch (err) {
				handleError(err);
				return false;
			} finally {
				setVPNLoading(false);
			}
		},
		[api],
	);

	const removeFromVPN = useCallback(
		async (nasId: string) => {
			setVPNLoading(true);

			try {
				await api.del(`/infra/vpn/remove/${nasId}`);
				setError(undefined);
				return true;
			} catch (err) {
				handleError(err);
				return false;
			} finally {
				setVPNLoading(false);
			}
		},
		[api],
	);

	const testVPNConnection = useCallback(
		async (nas_id: string): Promise<VPNTestResponse | undefined> => {
			setVPNTest(undefined);
			setVPNLoading(true);

			try {
				const result: VPNTestResponse = await api.get(`/infra/vpn/test/${nas_id}`);
				setError(undefined);
				setVPNTest(result);
				return result;
			} catch (err) {
				handleError(err);
			} finally {
				setVPNLoading(false);
			}
		},
		[api],
	);

	const getVPNParameters = useCallback(async (): Promise<VPNParametersResponse | undefined> => {
		setVPNParameters(undefined);
		setVPNLoading(true);

		try {
			const result: VPNParametersResponse = await api.get('/infra/vpn/parameters');
			setError(undefined);
			setVPNParameters(result);
			return result;
		} catch (err) {
			handleError(err);
		} finally {
			setVPNLoading(false);
		}
	}, [api]);

	function handleError(err: unknown) {
		if (typeof err === 'string') {
			setError(new APIError(err));
		} else if (err instanceof Error) {
			setError(new APIError(err.message));
		} else if (err instanceof APIError) {
			setError(err);
		}
	}

	function clearError() {
		setError(undefined);
	}

	function clearVPNTest() {
		setVPNTest(undefined);
	}

	return {
		addToVPN,
		removeFromVPN,
		testVPNConnection,
		getVPNParameters,
		vpnTest,
		vpnParameters,
		vpnLoading,
		error,
		clearError,
		clearVPNTest,
	};
}
