import { TFunction } from 'i18next';
import { CRUDFormGroupType } from '../components/CRUDFormGroupLegacy';
import { Subscription } from '../shared/models/Subscription';
import { formatMikrotikRateLimit } from '../shared/models/service/Speed';
import { dueDateValueExtractor, toLocaleLongDateString } from '../shared/utils';

export const readOnlyGroups = (t: TFunction): Array<CRUDFormGroupType> => [
	{
		key: 'plan-data',
		title: 'Plan',
		rows: [
			{
				key: 'row-1',
				fields: [
					{
						property: 'service_plan_name',
						label: 'Name',
						proportion: 'w-2/4',
						disabled: true,
					},
					{
						property: 'service_plan_price',
						label: 'Price',
						proportion: 'w-1/4',
						type: 'number',
						mask: 'decimal',
						disabled: true,
					},
					{
						property: 'service_plan_recurrency_interval',
						label: 'Recurrency',
						proportion: 'w-1/4',
						disabled: true,
					},
				],
			},
		],
	},
	{
		key: 'customer-data',
		title: 'Customer',
		rows: [
			{
				key: 'row-1',
				fields: [
					{
						property: 'client',
						label: 'Name',
						valueExtractor: (form) => (form as Subscription).client?.name || '',
						proportion: 'w-2/3',
						disabled: true,
					},
					{
						property: 'due_date',
						label: 'Due date',
						valueExtractor: (form) => dueDateValueExtractor(t, form as Subscription),
						proportion: 'w-1/3',
						disabled: true,
					},
				],
			},
		],
	},
	{
		key: 'connectivity-data',
		title: 'Connectivity (PPPoE Client)',
		rows: [
			{
				key: 'row-1',
				fields: [
					{
						property: 'connectivity_user',
						label: 'Username',
						proportion: 'w-1/3',
					},
					{
						property: 'connectivity_password',
						label: 'Password',
						proportion: 'w-1/3',
					},
					{
						property: 'connectivity_ip',
						label: 'IP',
						proportion: 'w-1/3',
					},
				],
			},
			{
				key: 'row-2',
				fields: [
					{
						property: 'held_at',
						label: 'Suspended at',
						valueExtractor: (form) =>
							(form as Subscription).held_at
								? toLocaleLongDateString((form as Subscription).held_at || '')
								: '',
						proportion: 'w-1/5',
						disabled: true,
					},
					{
						property: 'infra_nas_pool',
						label: 'NAS/Pool/IP',
						valueExtractor: (form) => {
							const pool = (form as Subscription).infra_nas_pool;
							if (!pool) return 'No pool found';
							const nas = pool.infra_nas;
							if (!nas) return 'No NAS found';
							return `${nas.name} (${nas.router_src_address}), Pool: ${pool.name}`;
						},
						proportion: 'w-2/5',
						disabled: true,
					},
					{
						property: 'connectivity_speed',
						label: 'Rate Limit',
						valueExtractor: (form) =>
							(form as Subscription).service_speed
								? formatMikrotikRateLimit((form as Subscription).service_speed)
								: '',
						proportion: 'w-2/5',
						disabled: true,
					},
				],
			},
		],
	},
	{
		key: 'payemnt-data',
		title: 'Payments',
		rows: [
			{
				key: 'row-1',
				fields: [
					{
						property: 'started_at',
						label: 'Activation',
						valueExtractor: (form) =>
							(form as Subscription).started_at
								? toLocaleLongDateString((form as Subscription).started_at || '')
								: '',
						proportion: 'w-1/6',
						disabled: true,
					},
					{
						property: 'last_successful_payment',
						label: 'Last Payment',
						valueExtractor: (form) =>
							(form as Subscription).last_successful_payment
								? toLocaleLongDateString((form as Subscription).last_successful_payment || '')
								: '',
						proportion: 'w-2/6',
						disabled: true,
					},
					{
						property: 'next_due_date',
						label: 'Next Payment',
						valueExtractor: (form) =>
							(form as Subscription).next_due_date
								? toLocaleLongDateString((form as Subscription).next_due_date || '')
								: '',
						proportion: 'w-2/6',
						disabled: true,
					},
					{
						property: 'canceled_at',
						label: 'Cancelation',
						valueExtractor: (form) =>
							(form as Subscription).canceled_at
								? toLocaleLongDateString((form as Subscription).canceled_at || '')
								: '',
						proportion: 'w-1/6',
						disabled: true,
					},
				],
			},
		],
	},
];
