import React from 'react';

import { DataTableField } from '../components/DataTable';
import { Filter } from '../slices/Filters';

import PaginatedPageLegacy from './PaginatedPageLegacy';
import { ISP } from '../shared/models/ISP';

const fields: Array<DataTableField<ISP>> = [
	{ title: 'Name', property: 'name' },
	{ title: 'Realm', property: 'realm' },
];
const endpoint = '/isp';
const formUrl = '/isp/new';

export default function ISPPage() {
	const filters: Array<Filter> = [
		{
			id: 'name',
			label: 'Name:',
			operator: {
				field: 'name',
				op: 'contains',
			},
		},
		{
			id: 'realm',
			label: 'Realm:',
			operator: {
				field: 'realm',
				op: 'contains',
			},
		},
	];

	return (
		<PaginatedPageLegacy
			title="ISPs"
			endpoint={endpoint}
			initialSortField="name"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
