import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { MenuItem } from '../types/MenuItem';
import { useProfile } from './useProfile';
import { TenantRole } from '../context/ProfileContext';
import config from '../shared/config';
import { Breadcrumb, Crumb } from '../types/Breadcrumb';
import { Cog, Folders, Home, Network, Orbit, Ticket, Users } from 'lucide-react';

const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

function useMenu() {
	const location = useLocation();
	const { tenant, saasTenant } = useProfile();
	const { t, i18n } = useTranslation('common');
	const [menu, setMenu] = useState<MenuItem[]>([]);
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb | undefined>();

	useEffect(() => {
		const generatedMenu = createMenu(t, tenant, saasTenant);
		const updatedMenu = setCurrentPathname(generatedMenu, location.pathname);
		setMenu(updatedMenu);

		const breadcrumbs = getCurrentBreadcrumb(updatedMenu, location.pathname);
		setBreadcrumbs(breadcrumbs || undefined);
	}, [location.pathname, tenant, saasTenant, t, i18n.language]);

	return { items: menu, breadcrumbs };
}

export default useMenu;

// Helper Functions

export function getCurrentBreadcrumb(menu: MenuItem[], pathname: string): Breadcrumb | null {
	// Recursive function to find and return the current breadcrumb
	function findDeepCurrentItem(
		items: MenuItem[],
		breadcrumbs: Crumb[] = [],
		parentPath: string = '',
	): Breadcrumb | null {
		for (const item of items) {
			const fullpath = parentPath ? `${parentPath}${item.to ?? ''}` : item.to ?? '';

			// Create the current crumb for this item
			const currentCrumb: Crumb = {
				path: item.to,
				title: item.title,
			};

			// Add the current crumb to the breadcrumb trail
			const currentBreadcrumbs = [...breadcrumbs, currentCrumb];

			// Check if the current item is active
			if (item.current) {
				// Create the breadcrumb object
				let breadcrumb: Breadcrumb = {
					fullpath,
					crumbs: currentBreadcrumbs,
				};

				// If the current item has nested items, check deeper for current items
				if (item.items) {
					const deeperBreadcrumb = findDeepCurrentItem(item.items, currentBreadcrumbs, fullpath);
					if (deeperBreadcrumb) {
						breadcrumb = deeperBreadcrumb; // Use the deepest breadcrumb found
					}
				}

				// Return the found breadcrumb
				return breadcrumb;
			}
		}

		// Return null if no current item is found
		return null;
	}

	// Start searching the menu for the current items
	const currentBreadcrumb = findDeepCurrentItem(menu);

	// If currentBreadcrumb is found and valid, check for additional segments in the pathname
	if (currentBreadcrumb && hasAdditionalSegment(pathname, currentBreadcrumb.fullpath)) {
		// Add an additional crumb for 'Form' at the end
		currentBreadcrumb.crumbs.push({
			title: 'Form',
		});
	}

	return currentBreadcrumb;
}

// Helper function to check if the route contains additional segments beyond the fullpath
function hasAdditionalSegment(pathname: string, fullpath: string): boolean {
	// Remove the fullpath from the pathname
	const remainingPath = pathname.replace(fullpath, '');

	// Check if there's anything left in the path (excluding leading/trailing slashes)
	return remainingPath.length > 1; // This will be true if there's more than just a '/'
}

function createMenu(t: TFunction, tenant?: TenantRole, saasTenant?: TenantRole): MenuItem[] {
	const menu: MenuItem[] = [getHomeMenuItem(t)];

	if (tenant?.role.startsWith('SAAS')) {
		menu.push(...getSaasMenu(t));
	}

	if (tenant?.role === 'ISPAdmin') {
		menu.push(...getIspAdminMenu(t, tenant, saasTenant));
	}

	return menu;
}

function getHomeMenuItem(t: TFunction): MenuItem {
	return {
		id: 'home',
		title: t('menu.dashboard'),
		icon: Home,
		to: '/',
	};
}

function getSaasMenu(t: TFunction): MenuItem[] {
	return [
		{
			id: 'services',
			title: t('menu.saas'),
			separator: true,
		},
		{
			id: 'saas-data',
			title: t('menu.administration'),
			icon: Cog,
			items: [
				{ id: 'audit', title: 'Audit', to: '/audit' },
				{ id: 'isp', title: 'ISP', to: '/isp' },
				{ id: 'users', title: 'Users', to: '/users' },
			],
		},
	];
}

function getIspAdminMenu(t: TFunction, tenant: TenantRole, saasTenant?: TenantRole): MenuItem[] {
	const accountMenu = getAccountMenu(t, tenant);

	const ispAdminMenu: MenuItem[] = [
		{
			id: 'services',
			title: t('menu.isp'),
			separator: true,
		},
		getAdministrationMenu(t, saasTenant),
		getComponentsMenu(t),
		getInfrastructureMenu(t),
		{ id: 'customers', title: t('menu.customers'), to: '/customers', icon: Users },
		getSubscriptionMenu(t),
	];

	if (config.stripe.featureFlag.indexOf(tenant.id) > -1) {
		ispAdminMenu.push(
			{
				id: 'account',
				title: t('menu.saas'),
				separator: true,
			},
			accountMenu,
		);
	}

	return ispAdminMenu;
}

function getAccountMenu(t: TFunction, tenant: TenantRole): MenuItem {
	const items = tenant.subscription
		? [
				{
					id: 'account-payment-methods',
					title: t('menu.submenu.account.paymentMethods'),
					to: '/account/payment-methods',
				},
				{
					id: 'account-subscription',
					title: t('menu.submenu.account.subscription'),
					to: '/account/subscription',
				},
			]
		: [
				{ id: 'account-activate', title: t('menu.submenu.account.activate'), to: '/account/activate' },
				{
					id: 'account-payment-methods',
					title: t('menu.submenu.account.paymentMethods'),
					to: '/account/payment-methods',
				},
			];

	return {
		id: 'account-menu',
		title: t('menu.account'),
		icon: Orbit,
		items,
	};
}

function getAdministrationMenu(t: TFunction, saasTenant?: TenantRole): MenuItem {
	const items = [{ id: 'audit', title: t('menu.submenu.administration.audit'), to: '/audit' }];

	if (saasTenant) {
		items.push({ id: 'import', title: t('menu.submenu.administration.import'), to: '/import' });
	}

	items.push({
		id: 'import-subscription',
		title: t('menu.submenu.administration.importSubscriptions'),
		to: '/import-subscription',
	});
	items.push({ id: 'settings', title: t('menu.submenu.administration.settings'), to: '/settings' });
	items.push({ id: 'users', title: t('menu.submenu.administration.users'), to: '/users' });

	return {
		id: 'administration',
		title: t('menu.administration'),
		icon: Cog,
		items,
	};
}

function getComponentsMenu(t: TFunction): MenuItem {
	const items = [
		{ id: 'service-plan', title: t('menu.submenu.components.plans'), to: '/service/plans' },
		{ id: 'service-speed', title: t('menu.submenu.components.speeds'), to: '/service/speeds' },
		{ id: 'service-type', title: t('menu.submenu.components.types'), to: '/service/types' },
	];

	return {
		id: 'components',
		title: t('menu.components'),
		icon: Folders,
		items,
	};
}

function getInfrastructureMenu(t: TFunction): MenuItem {
	return {
		id: 'infra',
		title: t('menu.infrastructure'),
		icon: Network,
		items: [
			{ id: 'service-nas', title: t('menu.submenu.infrastructure.nas'), to: '/infra/nas' },
			{ id: 'sync-config', title: t('menu.submenu.infrastructure.controlPanel'), to: '/infra/configuration' },
			{ id: 'diagnostics', title: t('menu.submenu.infrastructure.diagnostics'), to: '/infra/diagnostics' },
		],
	};
}

function getSubscriptionMenu(t: TFunction): MenuItem {
	return {
		id: 'subscriptions',
		title: t('menu.subscriptions'),
		icon: Ticket,
		items: [
			{
				id: 'payment-management',
				title: t('menu.submenu.subscriptions.paymentsManagement'),
				to: '/subscriptions/payments-management',
			},
			{
				id: 'payment-history',
				title: t('menu.submenu.subscriptions.paymentsHistory'),
				to: '/subscriptions/payments-history',
			},
			{ id: 'connectivity', title: t('menu.submenu.subscriptions.connectivity'), to: '/subscriptions' },
		],
	};
}

function setCurrentPathname(menu: MenuItem[], pathname: string): MenuItem[] {
	return menu.map((item) => {
		const isCurrent = isItemCurrent(item, pathname);
		const updatedItem = { ...item, current: isCurrent };

		if (item.items) {
			updatedItem.items = item.items.map((subItem) => ({
				...subItem,
				current: isItemCurrent(subItem, pathname),
			}));

			updatedItem.current = updatedItem.current || updatedItem.items.some((sub) => sub.current);
		}

		return updatedItem;
	});
}

function isItemCurrent(item: MenuItem, pathname: string): boolean {
	if (!item.to) return false;
	if (item.to === '/') {
		return pathname === '/';
	} else {
		const hasUuid = uuidPattern.test(pathname.split('/').pop() || '');
		const hasNew = pathname.split('/').pop() === 'new';
		return pathname === item.to || (pathname.startsWith(item.to + '/') && (hasUuid || hasNew));
	}
}
