import React from 'react';
import { Controller } from 'react-hook-form';
import { Expose } from 'class-transformer';

import WrapperPage from '../WrapperPage';
import BottomControl from '@/slices/BottomControl';
import { useForm } from '@/hooks/useForm';
import FormGroup from '@/slices/FormGroup';
import Input from '@/components/Input';
import InputAutoComplete from '@/components/InputAutoComplete';

interface TypeInputFormSpeed {
	id: string;
	description: string;
}

class TypeInputForm {
	@Expose()
	name: string = '';

	@Expose()
	service_speed_id: string | null = null;
	service_speed: TypeInputFormSpeed = {
		id: '',
		description: '',
	};

	@Expose()
	description: string = '';
}

const ENDPOINT = '/service/type';
const BACKWARD_URL = '/service/types';
const CREATE_URL = '/service/types/new';
const EMPTY_FORM = new TypeInputForm();

export default function TypeByIdPage() {
	const {
		handleSave,
		loading,
		error,
		clearError,
		form: { control, getValues, setValue, watch },
	} = useForm<TypeInputForm>({
		endpoint: ENDPOINT,
		createUrl: CREATE_URL,
		defaultValues: EMPTY_FORM,
		classConstructor: TypeInputForm,
	});

	watch('service_speed');
	watch('service_speed_id');

	return (
		<WrapperPage title="Services" error={error} onAlertClose={clearError}>
			<form onSubmit={(e) => e.preventDefault()} className="flex flex-1 justify-between flex-col">
				<div className="flex gap-2 mx-4">
					<FormGroup title="Data" grow>
						<div className="flex flex-col gap-2">
							<div className="flex gap-2">
								<div className="w-1/2">
									<Controller
										control={control}
										name="name"
										rules={{ required: 'Name is required' }}
										render={({ field }) => <Input label="Name" {...field} />}
									/>
								</div>
								<div className="w-1/2">
									<Controller
										control={control}
										name="service_speed_id"
										render={() => (
											<InputAutoComplete<TypeInputFormSpeed>
												field={{
													property: 'service_speed',
													label: 'Speed',
												}}
												value={getValues('service_speed') || EMPTY_FORM.service_speed}
												endpoint={'/service/speed'}
												searchField={'description'}
												parseKey={(row) => row.id}
												parseDisplay={(row) => row.description || ''}
												onSelect={(selected, selectedKey) => {
													setValue('service_speed', selected);
													setValue('service_speed_id', selectedKey);
												}}
												onClear={() => {
													setValue('service_speed', EMPTY_FORM.service_speed);
													setValue('service_speed_id', null);
												}}
											/>
										)}
									/>
								</div>
							</div>
							<div className="flex h-28">
								<Controller
									control={control}
									name="description"
									render={({ field }) => <Input label="Description" {...field} multiline full />}
								/>
							</div>
						</div>
					</FormGroup>
				</div>
				<BottomControl
					backwardUrl={BACKWARD_URL}
					createUrl={CREATE_URL}
					onSave={handleSave}
					loading={loading}
				/>
			</form>
		</WrapperPage>
	);
}
