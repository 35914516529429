import React, { useState } from 'react';

import DataTable, { DataTableAction, DataTableField } from '../../components/DataTable';
import Filters, { Filter } from '../../slices/Filters';

import { Type } from '../../shared/models/service/Type';
import usePaginatedTablePage from '@/hooks/usePaginatedTablePage';
import { useNavigate } from 'react-router-dom';
import { Plus, SquarePen, Trash2 } from 'lucide-react';
import Button from '@/components/Button';
import WhiteBox from '@/components/WhiteBox';
import ConfimationBox from '@/slices/ConfirmationBox';
import WrapperPage from '../WrapperPage';

const fields: Array<DataTableField<Type>> = [
	{ title: 'Name', property: 'name' },
	{
		title: 'Speed',
		property: 'service_speed.description',
		extractor: (row: Type) => row.service_speed?.description || '',
	},
];

const endpoint = '/service/type';
const formUrl = '/service/types/new';

export default function TypePage() {
	const filters: Array<Filter> = [
		{
			id: 'name',
			label: 'Name:',
			operator: {
				field: 'name',
				op: 'contains',
			},
		},
	];

	const {
		data,
		error,
		loading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		filterValues,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleRemove,
		handleClearError,
		handleFilterChange,
	} = usePaginatedTablePage<Type>({
		endpoint,
		filters,
	});

	const navigate = useNavigate();
	const [removeId, setRemoveId] = useState<string | undefined>();

	const actions: Array<DataTableAction<Type>> = [
		{
			title: 'Editar',
			icon: SquarePen,
			action: (row) => navigate(formUrl.replace('/new', `/${row.id}`)),
		},
		{
			title: 'Remover',
			icon: Trash2,
			action: async (row) => {
				setRemoveId(row.id);
			},
		},
	];

	function handleNew() {
		formUrl && navigate(formUrl);
	}

	async function handleOkRemove() {
		if (!removeId) return;
		handleRemove(removeId);
		setRemoveId(undefined);
	}

	function handleCancelRemove() {
		setRemoveId(undefined);
	}

	return (
		<WrapperPage title="Services" error={error} onAlertClose={handleClearError}>
			<div className="mx-4 flex flex-col gap-4">
				<div className="flex items-center justify-between">
					{filters && (
						<Filters filters={filters} filterValues={filterValues} onFilterChange={handleFilterChange} />
					)}
					{formUrl && <Button icon={Plus} style="roundedOutline" onClick={handleNew} />}
				</div>
				<WhiteBox>
					<DataTable
						fields={fields}
						actions={actions}
						rows={data}
						pages={{ page, size: pageSize, total: totalRecords }}
						onPageSize={handleOnPageSize}
						onPage={handleOnPage}
						onRefresh={handleOnRefresh}
						loading={loading}
						sort={sortField ? { field: sortField, order: sortOrder } : undefined}
						onSort={handleOnSort}
					/>
				</WhiteBox>
				{removeId && (
					<ConfimationBox
						title="Action Confirmation"
						message="Are you sure you want to remove this record?"
						onOk={handleOkRemove}
						onCancel={handleCancelRemove}
						inverted
					/>
				)}
			</div>
		</WrapperPage>
	);
}
