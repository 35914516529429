import React from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroupLegacy';
import { useProfile } from '../hooks/useProfile';
import { RoleType, roleTypes, User, UserISP } from '../shared/models/User';
import { BeforeSubmitType, useCRUDFormPageLegacy } from '../hooks/useCRUDFormPageLegacy';
import GenericCRUDFormPageLegacy from './GenericCRUDFormPageLegacy';
import { CRUDFormFieldTypeLegacy } from '../components/CRUDFormFieldLegacy';
import { CRUDFormSelectTypeLegacy } from '../components/CRUDFormSelectLegacy';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import FormGroup from '../slices/FormGroup';
import usePaginatedQuery from '../hooks/api/usePaginatedQuery';
import { ISP } from '../shared/models/ISP';
import Select from '../components/Select';
import { TenantRole } from '../context/ProfileContext';
import Button from '../components/Button';
import { LoaderCircle } from 'lucide-react';

const endpoint = '/management/users';
const createUrl = '/users/new';
const backwardUrl = '/users';

function generateHandleBeforeSubmit({ saasAdmin, tenant }: { saasAdmin: boolean; tenant?: TenantRole }) {
	return (payload: BeforeSubmitType, form: User): BeforeSubmitType => {
		if (saasAdmin) {
			if (!form.user_isp) {
				form.user_isp = [];
			}

			const ispsDict: { [key: string]: string } | undefined = form.user_isp
				.filter((ispRole) => ispRole.role)
				.reduce(
					(acc, ispRole) => {
						if (ispRole.role) acc[ispRole.isp.id] = ispRole.role;
						return acc;
					},
					{} as { [key: string]: string },
				);

			payload.saas_role = form.saas_role;
			return {
				...payload,
				isps: ispsDict,
			};
		}

		if (!tenant) throw Error('tenant must be set when ISPAdmin');
		const role = payload.role;
		delete payload.isp_id;
		delete payload.role;
		return {
			...payload,
			isps: { [`${tenant.id}`]: role },
		};
	};
}

function generateOnCreate(saasAdmin: boolean, navigate: NavigateFunction) {
	return (id: string) => {
		if (saasAdmin) navigate(createUrl.replace('/new', `/${id}`));
		else navigate(backwardUrl);
	};
}

function UserByIdPage() {
	const navigate = useNavigate();
	const { currentIsSaas: saasAdmin, tenant } = useProfile();
	const { response: ispResponse, loading: ispLoading } = usePaginatedQuery<ISP>('/isp', {
		performFetch: saasAdmin,
		initialPageSize: 200,
	});

	const saasRoles = roleTypes
		.map((role) => ({ key: role, label: role }))
		.filter((role) => role.key.startsWith('SAAS'));
	const roles = roleTypes.map((role) => ({ key: role, label: role })).filter((role) => !role.key.startsWith('SAAS'));

	const role_field: CRUDFormSelectTypeLegacy = {
		property: 'role',
		label: 'Role',
		highlight: true,
		proportion: 'w-1/3',
		options: roles,
	};
	const email_field: CRUDFormFieldTypeLegacy = {
		property: 'email',
		label: 'Email',
		highlight: true,
		proportion: 'w-2/3',
		type: 'email',
	};
	const firstRowFields: Array<CRUDFormFieldTypeLegacy | CRUDFormSelectTypeLegacy> = [email_field, role_field];

	const given_name_field: CRUDFormFieldTypeLegacy = {
		property: 'given_name',
		label: 'First name',
		proportion: 'w-1/2',
	};
	const family_name_field: CRUDFormFieldTypeLegacy = {
		property: 'family_name',
		label: 'Last name',
		proportion: 'w-1/2',
	};

	const groups: Array<CRUDFormGroupType> = [
		{
			key: 'basic-data',
			title: 'Data',
			rows: [
				{
					key: 'row-1',
					fields: firstRowFields,
				},
				{
					key: 'row-2',
					fields: [given_name_field, family_name_field],
				},
			],
		},
	];

	const { isCreate, form, setForm, handleSave, loading, error } = useCRUDFormPageLegacy(
		endpoint,
		groups,
		createUrl,
		undefined,
		generateHandleBeforeSubmit({ saasAdmin, tenant }),
		generateOnCreate(saasAdmin, navigate),
	);
	const user = form as User;

	if (saasAdmin) {
		const indexToRemove = firstRowFields.findIndex((item) => item === role_field);

		if (indexToRemove !== -1) {
			firstRowFields.splice(indexToRemove, 1);
		}

		email_field.proportion = 'w-full';
	}

	if (!isCreate) {
		email_field.disabled = true;
		given_name_field.disabled = true;
		family_name_field.disabled = true;
	}

	function getUserISPRole(isp: ISP) {
		return user.user_isp?.find((userIsp) => userIsp.isp.id === isp.id)?.role || '';
	}

	function grantUserISPRole(isp: ISP, role: RoleType) {
		if (!user.user_isp) {
			user.user_isp = [];
		}

		let userISP: UserISP | undefined = user.user_isp?.find((userISP) => userISP.isp_id === isp.id);
		if (!userISP) {
			userISP = {
				isp_id: isp.id,
				isp: isp,
				role: role,
			};
			user.user_isp?.push(userISP);
		}

		userISP.role = role;
		setForm({ ...user });
	}

	function revokeUserISPRole(isp: ISP) {
		user.user_isp = user.user_isp?.filter((userISP) => userISP.isp_id !== isp.id);
		setForm({ ...user });
	}

	return (
		<GenericCRUDFormPageLegacy
			title="Users"
			groups={groups}
			backwardUrl={backwardUrl}
			createUrl={createUrl}
			form={form as never}
			setForm={setForm}
			loading={loading}
			error={error}
			handleSave={handleSave}
			minimalActions
		>
			{saasAdmin && (
				<div className="flex flex-col gap-4">
					{tenant?.role === 'SAASAdmin' && (
						<FormGroup title="SaaS Role">
							<div className="flex gap-2">
								<div className="w-60">
									<Select
										options={saasRoles}
										value={(form as User).saas_role || ''}
										placeholder="No role selected"
										onChange={(key) => setForm({ ...form, saas_role: key })}
									/>
								</div>
								{(form as User).saas_role && (
									<Button
										text="(revoke)"
										variant="link"
										onClick={() => setForm({ ...form, saas_role: undefined })}
									/>
								)}
							</div>
						</FormGroup>
					)}
					<FormGroup title="ISP & Roles">
						{ispLoading && (
							<div className="w-fullflex items-center justify-center">
								<LoaderCircle className="animate-spin" size={32} />
							</div>
						)}
						<div className="flex flex-col gap-2">
							<div className="grid grid-flow-row grid-cols-1 lg:grid-cols-2">
								{!ispLoading &&
									ispResponse &&
									ispResponse.rows.map((isp) => (
										<div key={isp.id} className="flex items-center gap-2">
											<div className="w-60">
												<Select
													options={roles}
													value={getUserISPRole(isp)}
													placeholder="No role selected"
													onChange={(text) => grantUserISPRole(isp, text as RoleType)}
													full
												/>
											</div>
											<span
												className={`text-sm ${getUserISPRole(isp) ? 'font-bold' : 'text-light'}`}
											>
												{isp.name} {!getUserISPRole(isp) && '(no access)'}
											</span>
											{getUserISPRole(isp) && (
												<Button
													text="(revoke)"
													variant="link"
													onClick={() => revokeUserISPRole(isp)}
												/>
											)}
										</div>
									))}
							</div>
						</div>
					</FormGroup>
				</div>
			)}
		</GenericCRUDFormPageLegacy>
	);
}

export default UserByIdPage;
